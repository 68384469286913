<template>
  <div class="wrap">
    <!-- header -->
    <div class="layer1 flex-row justify-center align-center">
      <div class="content justify-center align-center">
        <!-- logo -->
        <div class="group1 flex-row justify-center align-center">
          <router-link to="/home" class="">
            <img
              class="label1"
              referrerpolicy="no-referrer"
              src="./assets/img/logo.png"
            />
            <span class="info1">{{ $t('title') }}</span>
          </router-link>
        </div>
        <div class="group2 flex-row">
          <router-link  class="word1 " to="/home">{{ $t('nav1') }}</router-link>
          <router-link  class="word1 " to="/introduction">{{ $t('nav2') }}</router-link>
          <router-link  class="word1 active" to="/settleIn/step1">{{ $t('nav3') }}</router-link>
        </div>
      </div>
      <!-- <div class="group3 flex-row justify-center align-center">
        <img
          class="label2"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng92e66123570e03a704db124af0122b6ccc5c854875de6b8c41a181099ed78a34.png"
        />
        <router-link to="/">{{$t('login')}}</router-link>
        <span class="word5">繁</span>
        <span class="word6">简</span>
      </div> -->
    </div>

    <div class="main main1">
      <div class="content">
        <div class="left-content">
          <ul>
            <li>
              <router-link to="/settleIn/step1">
                <a href="javascript: ;">
                  <span class="p1">{{ $t('txt105') }}</span>
                  <span class="p2">{{ $t('txt104') }}</span>
                </a>
              </router-link>
            </li>
            <li>
              <router-link to="/settleIn/step2">
                <a class="active" href="javascript: ;">
                  <span class="p1">{{ $t('txt106') }}</span>
                  <span class="p2">{{ $t('txt107') }}</span>
                </a>
              </router-link>
            </li>
            <li>
              <router-link to="/settleIn/step3">
                <a href="javascript: ;">
                  <span class="p1">{{ $t('txt108') }}</span>
                  <span class="p2">{{ $t('txt109') }}</span>
                </a>
              </router-link>
            </li>
            <li>
              <router-link to="/settleIn/step4">
                <a href="javascript: ;">
                  <span class="p1">{{ $t('txt110') }}</span>
                  <span class="p2">{{ $t('txt111') }}</span>
                </a>
              </router-link>
            </li>
            <li>
              <router-link to="/settleIn/step5">
                <a href="javascript: ;">
                  <span class="p1">{{ $t('txt112') }}</span>
                  <span class="p2">{{ $t('txt113') }}</span>
                </a>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="right-content">
          <div class="banner banner3">
            <p class="p1">{{ $t('txt107') }}</p>
            <p class="p2">{{ $t('txt120') }}</p>
          </div>
          <div class="right-content-bottom" style="height:240px">
            <p class="p3">{{ $t('txt121') }}</p>
            <p class="p4">{{ $t('txt122') }}</p>
            <p class="p5">{{ $t('txt123') }}</p>
            <p class="p6">{{ $t('txt127') }}</p>
          </div>
        </div>
      </div>
    </div>


    <!-- footer -->
    <div class="footer">
      <div class="content">
        <div class="layer7">
          <img src="./assets/img/logo2.png" alt="">
          <p class="p1">&copy;&nbsp;{{year}}&nbsp;&nbsp;{{$t('txt43')}}</p>
        </div>
        <div class="layer8">
          <p class="p1">{{$t('txt44')}} </p>
          <p class="p2">{{$t('txt45')}}</p>
          <div>
            <div class="block1">
              <img src="./assets/img/icon6.png" alt="">
              <p class="p3">{{$t('txt46')}}</p>
            </div>
            <div class="block1">
              <img src="./assets/img/icon7.png" alt="">
              <p class="p3 p4">{{$t('txt47')}}</p>
            </div>
            <a href="https://www.facebook.com/ChinaMobileHK/" target="_blank"><img src="./assets/img/icon8.png" class="img1" alt=""></a>
            <a href="https://www.instagram.com/chinamobilehk/" target="_blank"><img src="./assets/img/icon9.png" class="img2" alt=""></a>
            <a href="https://www.youtube.com/user/chinamobilehongkong" target="_blank"><img src="./assets/img/icon10.png" class="img3" alt=""></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SettleIn',
  components: {},
  props: [],
  data() {
    return {
      year: (new Date()).getFullYear()
    }
  },
  computed: {},
  watch: {},
  methods: {
    
  },
  created() {
    
  },
  //mounted() {},
  //beforeDestroy() {},
  //update() {},
  //activited() {},
  //beforeRouteUpdate() {},
  //filters: {},
};
</script>

<style scoped lang="css" src="./assets/index.css" />