<template>
  <div class="wrap">

    <!-- header -->
    <div class="layer1 flex-row justify-center align-center">
      <div class="content justify-center align-center">
        <!-- logo -->
        <div class="group1 ">
          <router-link to="/home" class="">
            <img
              class="label1"
              referrerpolicy="no-referrer"
              src="./assets/img/logo.png"
            />
            <span class="info1">{{ $t('title') }}</span>
          </router-link>
        </div>

        <div class="group2 flex-row">
          <router-link  class="word1 " to="/home">{{ $t('nav1') }}</router-link>
          <router-link  class="word1 active" to="/introduction">{{ $t('nav2') }}</router-link>
          <router-link  class="word1 " to="/settleIn/step1">{{ $t('nav3') }}</router-link>
        </div>
      </div>
      <!-- <div class="group3 flex-row justify-center align-center">
        <img
          class="label2"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng92e66123570e03a704db124af0122b6ccc5c854875de6b8c41a181099ed78a34.png"
        />
        <router-link to="/">{{$t('login')}}</router-link>
        <span class="word5">繁</span>
        <span class="word6">简</span>
      </div> -->
    </div>

    <!-- banner -->
    <div class="banner banner1">
      <div class="content">
        <img src="./assets/img/banner1.png" alt="">
      </div>
    </div>

    <!-- 合作优势 -->
    <div class="banner banner2">
      <div class="content">
        <h3>{{ $t('txt48') }}</h3>
        <p class="intro1">{{ $t('txt175') }}</p>
        <div class="card card1">
          <img src="./assets/img/icon11.png" class="img" alt="">
          <p class="p1">{{ $t('txt49') }}</p>
          <p class="p2">{{ $t('txt50') }}</p>
        </div>
        <div class="card card2">
          <img src="./assets/img/icon12.png" class="img" alt="">
          <p class="p1">{{ $t('txt51') }}</p>
          <p class="p2">{{ $t('txt52') }}</p>
        </div>
        <div class="card card3">
          <img src="./assets/img/icon13.png" class="img" alt="">
          <p class="p1">{{ $t('txt53') }}</p>
          <p class="p2">{{ $t('txt54') }}</p>
        </div>
      </div>
    </div>


    <!-- 积分介绍 -->
    <div class="banner banner3">
      <div class="content">
        <h3>{{ $t('txt55') }}</h3>
        <p class="intro1">{{ $t('txt56') }}</p>
        <img src="./assets/img/img10.png" alt="">
      </div>
    </div>


    <!-- 推广活动 -->
    <div class="banner banner4">
      <div class="content">
        <h3>{{ $t('txt57') }}</h3>
        <div class="card4">
          <img src="./assets/img/img1.png" alt="">
          <div class="marker2"></div>
          <div class="card-content">
            <p class="title">{{ $t('txt162') }}</p>
          </div>
        </div>
        <div class="card4">
          <img src="./assets/img/img2.png" alt="">
          <div class="marker2"></div>
          <div class="card-content">
            <p class="title">{{ $t('txt164') }}</p>
          </div>
        </div>
        <div class="card4">
          <img src="./assets/img/img3.png" alt="">
          <div class="marker2"></div>
          <div class="card-content">
            <p class="title">{{ $t('txt165') }}</p>
          </div>
        </div>
        <div class="card4" style="margin-right:0;">
          <img src="./assets/img/img4.png" alt="">
          <div class="marker2"></div>
          <div class="card-content">
            <p class="title">{{ $t('txt166') }}</p>
          </div>
        </div>
        <div class="card4">
          <img src="./assets/img/img5.png" alt="">
          <div class="marker2"></div>
          <div class="card-content">
            <p class="title">{{ $t('txt171') }}</p>
          </div>
        </div>
        <div class="card4">
          <img src="./assets/img/img6.png" alt="">
          <div class="marker2"></div>
          <div class="card-content">
            <p class="title">{{ $t('txt172') }}</p>
          </div>
        </div>
        <div class="card4">
          <img src="./assets/img/img7.png" alt="">
          <div class="marker2"></div>
          <div class="card-content">
            <p class="title">{{ $t('txt173') }}</p>
          </div>
        </div>
        <div class="card4" style="margin-right:0;">
          <img src="./assets/img/img8.png" alt="">
          <div class="marker2"></div>
          <div class="card-content">
            <p class="title">{{ $t('txt174') }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <div class="banner banner5">
      <div class="content">
        <h3>{{ $t('txt66') }}</h3>
        <img src="./assets/img/zx.png" class="imgs" alt="">
        <img src="./assets/img/zz.png" class="imgs" alt="">
        <img src="./assets/img/tx.png" class="imgs" alt="">
        <img src="./assets/img/ts.png" class="imgs" alt="">
        <img src="./assets/img/livi-bank.png" class="imgs" alt="">
        <img src="./assets/img/ht.png" class="imgs" alt="">
        <img src="./assets/img/rs.png" class="imgs" alt="">
        <img src="./assets/img/tp.png" class="imgs" alt="">
        <img src="./assets/img/xm.png" class="imgs" alt="">
        <img src="./assets/img/TF.png" class="imgs" alt="">
        <img src="./assets/img/bk.png" class="imgs" alt="">
        <img src="./assets/img/wyx.png" class="imgs" alt="">
        <img src="./assets/img/zy.png" class="imgs" alt="">
        <img src="./assets/img/sn.png" class="imgs" alt="">
      </div>
    </div>


    <!-- 申请加入 -->
    <div class="banner" style="height:630px;" id="join">
      <div class="content" style="height:630px;overflow:hidden">
        <h3 style="margin-top:40px;margin-bottom:40px;">{{ $t('txt67') }}</h3>
        <el-form :model="form" ref="ruleForm" label-width="100px" :rules="rules">
          <el-form-item :label="$t('txt68')" prop="company">
            <el-input :placeholder="$t('txt69')" v-model="form.company"></el-input>
          </el-form-item>
          <el-form-item :label="$t('txt70')" prop="classify">
            <el-select v-model="form.classify" :placeholder="$t('txt71')">
              <el-option :value="$t('txt72')">{{ $t('txt72') }}</el-option>
              <el-option :value="$t('txt73')">{{ $t('txt73') }}</el-option>
              <el-option :value="$t('txt74')">{{ $t('txt74') }}</el-option>
              <el-option :value="$t('txt75')">{{ $t('txt75') }}</el-option>
              <el-option :value="$t('txt76')">{{ $t('txt76') }}</el-option>
              <el-option :value="$t('txt77')">{{ $t('txt77') }}</el-option>
              <el-option :value="$t('txt78')">{{ $t('txt78') }}</el-option>
              <el-option :value="$t('txt79')">{{ $t('txt79') }}</el-option>
              <el-option :value="$t('txt80')">{{ $t('txt80') }}</el-option>
              <el-option :value="$t('txt81')">{{ $t('txt81') }}</el-option>
              <el-option :value="$t('txt82')">{{ $t('txt82') }}</el-option>
              <el-option :value="$t('txt83')">{{ $t('txt83') }}</el-option>
              <el-option :value="$t('txt84')">{{ $t('txt84') }}</el-option>
              <el-option :value="$t('txt85')">{{ $t('txt85') }}</el-option>
              <el-option :value="$t('txt86')">{{ $t('txt86') }}</el-option>
              <el-option :value="$t('txt87')">{{ $t('txt87') }}</el-option>
              <el-option :value="$t('txt88')">{{ $t('txt88') }}</el-option>
              <el-option :value="$t('txt89')">{{ $t('txt89') }}</el-option>
              <el-option :value="$t('txt90')">{{ $t('txt90') }}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('txt91')" prop="purpose">
            <el-select v-model="form.purpose" multiple :placeholder="$t('txt71')">
              <el-option :value="$t('txt92')">{{ $t('txt92') }}</el-option>
              <el-option :value="$t('txt161')">{{ $t('txt161') }}</el-option>
              <el-option :value="$t('txt93')">{{ $t('txt93') }}</el-option>
              <el-option :value="$t('txt94')">{{ $t('txt94') }}</el-option>
              <el-option :value="$t('txt90')">{{ $t('txt90') }}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('txt95')" prop="link">
            <el-input type="text" :placeholder="$t('txt96')" v-model="form.link"/>
          </el-form-item>
          <el-form-item :label="$t('txt97')" prop="address">
            <el-input type="text" :placeholder="$t('txt98')" v-model="form.address"/>
          </el-form-item>
          <el-form-item :label="$t('txt99')" prop="phone">
            <el-input type="text" :placeholder="$t('txt100')" v-model.number="form.phone" maxlength="8"/>
          </el-form-item>
          <el-form-item :label="$t('txt101')">
            <el-input type="text" :placeholder="$t('txt102')" v-model="form.url"/>
          </el-form-item>
          <span class="btn2" @click="submit('ruleForm')">{{ $t('txt103') }}</span>
        </el-form>
      </div>
    </div>

    

    <!-- footer -->
    <div class="footer">
      <div class="content">
        <div class="layer7">
          <img src="./assets/img/logo2.png" alt="">
          <p class="p1">&copy;&nbsp;{{year}}&nbsp;&nbsp;{{$t('txt43')}}</p>
        </div>
        <div class="layer8">
          <p class="p1">{{$t('txt44')}} </p>
          <p class="p2">{{$t('txt45')}}</p>
          <div>
            <div class="block1">
              <img src="./assets/img/icon6.png" alt="">
              <p class="p3">{{$t('txt46')}}</p>
            </div>
            <div class="block1">
              <img src="./assets/img/icon7.png" alt="">
              <p class="p3 p4">{{$t('txt47')}}</p>
            </div>
            <a href="https://www.facebook.com/ChinaMobileHK/" target="_blank"><img src="./assets/img/icon8.png" class="img1" alt=""></a>
            <a href="https://www.instagram.com/chinamobilehk/" target="_blank"><img src="./assets/img/icon9.png" class="img2" alt=""></a>
            <a href="https://www.youtube.com/user/chinamobilehongkong" target="_blank"><img src="./assets/img/icon10.png" class="img3" alt=""></a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { $t } from '../../i18n/index'

export default {
  name: 'Introduction',
  components: {
  },
  props: [],
  data() {
    var checkEmail = (rule, value, callback) => {
      var myreg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
      if (value === '') {
        callback(new Error($t('txt97')+$t('txt142')));
      } else {
        if (!myreg.test(value)) {
          callback(new Error($t('txt147')));
        }
        callback();
      }
    };
    return {
      form: {
        company: '',
        classify: '',
        purpose: [],
        link: '',
        address: '',
        phone: '',
        url: ''
      },
      year: (new Date()).getFullYear(),
      rules: {
        company: [
          {required: true, message: $t('txt68')+$t('txt142'), trigger: 'blur'}
        ],
        classify: [
          {required: true, message: $t('txt70')+$t('txt143'), trigger: 'change'}
        ],
        purpose: [
          {required: true, message: $t('txt91')+$t('txt143'), trigger: 'change'}
        ],
        link: [
          {required: true, message: $t('txt95')+$t('txt142'), trigger: 'blur'}
        ],
        address: [
          {required: true, message: $t('txt97')+$t('txt142'), trigger: 'blur'},
          { validator: checkEmail, trigger: 'blur' }
        ],
        phone: [
          {required: true, message: $t('txt99')+$t('txt142'), trigger: 'blur'},
          {type: 'number', message: $t('txt99')+$t('txt146'), trigger: 'blur'},
        ]
      }
    }
  },
  computed: {},
  watch: {},
  methods: {
    submit(formName){
      this.$refs[formName].validate((valid) => {
        if(valid){
          var xhr = xhr = new XMLHttpRequest()
      
          // TODO
          // const url = process.env.NODE_ENV === "production" ? 'https://mylink.hk.chinamobile.com/mylink/via/work/add' : 'https://t01.capi.via.cool/mylink/via/work/add'
          // const url = 'https://t01.capi.via.cool/mylink/via/work/add'
          const url = 'https://mylink.hk.chinamobile.com/mylink/via/work/add'

          xhr.open('post', url);
          xhr.setRequestHeader('Content-Type', 'application/json')
          this.form.purpose = this.form.purpose.join(',')

          xhr.send(JSON.stringify(this.form));

          let $that = this

          xhr.onreadystatechange = function(){
            if(xhr.readyState == 4){
              if(xhr.status == 200){
                var data = xhr.responseText;
                data = JSON.parse(data)
                if(data.code == 0 || data.code == '0'){
                  // $that.form = {
                  //               company: '',
                  //               classify: '',
                  //               purpose: '',
                  //               link: '',
                  //               address: '',
                  //               phone: '',
                  //               url: ''
                  //             }
                  $that.$refs[formName].resetFields();
                  $that.$message({
                    message: $t('txt144'),
                    type: 'success'
                  });
                  
                }else{
                  $that.$message({
                    message: $t('txt145'),
                    type: 'error'
                  });
                }
              }
            }
          }
        } else {
          this.$message({
            message: $t('txt145'),
            type: 'error'
          });
        }
      })
    },
    changeSelect1() {
      this.$refs['select1'].style.color = '#202020'
    },
    changeSelect2() {
      this.$refs['select2'].style.color = '#202020'
    },
    scroll() {
      this.$nextTick( ()=> {
        document.documentElement.scrollTop = 2800
      })
    }
  },
  created() {
    if(this.$route.query.join){
      this.scroll()
    }
  },
  mounted() {
    
  },
  //beforeDestroy() {},
  //update() {},
  //activited() {},
  //beforeRouteUpdate() {},
  //filters: {},
};
</script>

<style scoped lang="css" src="./assets/index.css" />