export default {
  title: 'MyLink商盟接入平台',
  nav1: '首頁',
  nav2: '合作介紹',
  nav3: '入駐指南',
  login: '登錄',
  txt1: '關於MyLink',
  txt2: 'MyLink是中國移動香港全方位生活服務App，服務全港用戶提供通訊服務，及豐富且有吸引力的生活資訊優惠，多種合作模式供您選擇。',
  txt3: '業務推廣合作',
  txt4: '品牌服務推廣，業務轉化提升',
  txt5: '用戶權益合作',
  txt6: '平臺權益互換，提升用戶價值體驗',
  txt7: '行業服務合作',
  txt8: '機票、酒店、購物、打車、休閒娛樂等',
  txt9: '線下門店合作',
  txt10: '門店優惠、線下支付優惠等服務鋪設',
  txt11: 'MyLink 總用戶',
  txt12: '每日活躍用戶',
  txt13: '每月活躍用戶',
  txt14: 'Google Play',
  txt15: '·「生活」類中長期排名第',
  txt16: '·  熱門免費—最高總排名第',
  txt17: 'App Store',
  txt18: '·「生活」類中長期排名第',
  txt19: '·  熱門免費—最高總排名第',
  txt20: '入駐流程',
  txt21: '商務洽談',
  txt22: '約5天',
  txt23: '· 提交商務合作意向 ',
  txt24: '· 雙方洽談確認合作進入下一流程',
  txt25: '商務簽約',
  txt26: '約3天',
  txt27: '· 雙方商務推進簽約事宜 ',
  txt28: '· 初步敲定技術對接方案，提供合作資料',
  txt29: '開發及聯調測試',
  txt30: '持續',
  txt31: '· 獲得商家平臺登錄資訊',
  txt32: '· 查看對接文檔，進行開發',
  txt33: '· 自助測試',
  txt34: '· UAT環境驗收測試',
  txt35: '上線宣傳',
  txt36: '約10天',
  txt37: '· 線上配置並最終驗收測試',
  txt38: '· 審批上線',
  txt39: '自助服務管理',
  txt40: '(此環節預計需要1-2工作日)',
  txt41: '· 自有服務維護，活動情況實時監控',
  txt42: '馬上申請加入MyLink商盟',
  txt43: '中國移動香港有限公司版權所有',
  txt44: '關注或聯繫我們 ',
  txt45: '下載MyLink App、關註官方公眾號，關註更多資訊，享受移動服務 ',
  txt46: 'MyLink App',
  txt47: 'WeChat官方帳號',
  txt48: '合作優勢',
  txt49: '強大本地顧客群',
  txt50: 'MyLink平臺擁有超過400萬個本地客戶，為您開拓新客源',
  txt51: '多元營銷助力品牌',
  txt52: '透過不同的推廣及宣傳，增加您的品牌及商品曝光',
  txt53: '積分獎賞用戶粘性高',
  txt54: '用戶透過消費購取積分，鼓勵客戶重複消費，亦可積分兌換積分獎賞，共同構建積分生態圈',
  txt55: 'MyLink商盟生態模式',
  txt56: 'MyLink 圍繞商圈模式、會員權益，與行業服務進行深度合作，為用戶提供尊享餐飲、旅遊、購物、保險等多種類型的權益優惠，通過MyLink 的龐大客戶群為商戶引流、創造商機。',
  txt57: '聯合推廣活動',
  txt58: '纍計參與活動PV300+,',
  txt59: '纍計參與活動PV300+,',
  txt60: '纍計參與活動PV300+,',
  txt61: '纍計參與活動PV300+,',
  txt62: '纍計參與活動PV300+,',
  txt63: '纍計參與活動PV300+,',
  txt64: '纍計參與活動PV300+,',
  txt65: '纍計參與活動PV300+,',
  txt66: 'MyLink商戶聯盟合作夥伴',
  txt67: '申請加入MyLink商盟',
  txt68: '公司名稱',
  txt69: '請輸入貴司全稱',
  txt70: '產品/服務',
  txt71: '請選擇',
  txt72: '饮食',
  txt73: '時尚服飾 配件',
  txt74: '美容',
  txt75: '汽車',
  txt76: '生活百貨',
  txt77: '電器數碼',
  txt78: '娛樂活動',
  txt79: '寵物',
  txt80: '藝術/興趣/教育',
  txt81: '健康/醫療',
  txt82: '母嬰專區',
  txt83: '運動/戶外專區',
  txt84: '婚禮專區',
  txt85: '遊戲/模型',
  txt86: '家居裝修',
  txt87: '珠寶鐘錶',
  txt88: '金融',
  txt89: '旅遊及休閒',
  txt90: '其他',
  txt91: '合作意向',
  txt92: '業務推廣合作（品牌合作）',
  txt93: '行業服務合作（商戶入駐）',
  txt94: '線下門店合作（品牌合作）',
  txt95: '聯絡人',
  txt96: '請輸入聯絡人姓名',
  txt97: '電郵',
  txt98: '請輸入聯絡電郵地址',
  txt99: '電話號碼',
  txt100: '請輸入電話號碼',
  txt101: '網站/APP',
  txt102: '請輸入貴司網站/App名稱',
  txt103: '確認',
  txt104: '商務洽談',
  txt105: '第一步',
  txt106: '第二步',
  txt107: '商務簽約',
  txt108: '第三步',
  txt109: '開發及聯調測試',
  txt110: '第四步',
  txt111: '上線宣傳',
  txt112: '第五步',
  txt113: '自助服務管理',
  txt114: 'MyLink在香港地區擁有廣泛的用戶資源，為了更好地服務用戶，我們希望有更多的商家可以為MyLink提供更多更優質的產品或服務。如果您是銀行、證券、保險、電商、旅遊、餐廳、本地生活服務、行政服務等行業，可與我們聯繫入駐MyLink商家聯盟。',
  txt115: '發送郵件聯繫合作事項',
  txt116: '您可發送電子郵件至',
  txt117: '，留下您企業所屬行業，希望合作的內容，以及您的聯繫方式，至少包括姓名、電話、公司名稱。我們會有商務人員和您取得聯絡。',
  txt118: 'MyLink商家聯盟整體流程',
  txt119: '入駐整體流程如圖所示',
  txt120: '經過初步商務洽談後，您將與MyLink簽署合作協議。',
  txt121: '提供商家名稱、聯繫人等資訊',
  txt122: '為了保障後續合作的順利進行，需要您提前準備以下資料：',
  txt123: '· 商戶名稱、合作業務簡介及時間、項目負責人聯繫方式、訪問服務器白名單IP地址、宣傳資料等',
  txt124: '· 合作方公司簡稱：用於品牌露出展示',
  txt125: '· 展示圖片：',
  txt126: '具體需商家提供資料待整理完善',
  txt127: '當MyLink收集完成您的資料後，會在MyLink系統中創建合作專案ID等內容',
  txt128: '在第二步進行的同時，您可以安排技術開發對接工作。',
  txt129: '獲得商家平臺登錄資訊',
  txt130: 'MyLink的工作人員會通過郵件來發送商家聯盟平臺的登錄資訊，包括登錄地址、帳戶名、密碼。此部分資訊可轉交您的技術人員。',
  txt131: '查看對接文檔，進行開發',
  txt132: '登錄商家系統後，技術人員可在後臺查看到被分配的AppKey、AppSecret，ClientID、ClientSecret等資訊。可查看API對接流程圖，以及具體介面文檔描述。同時MyLink提供已經封裝好的SDK及使用說明，技術人員可下載SDK並加入到代碼中直接使用，更大程度地減少開發工作量。',
  txt133: '自助測試',
  txt134: '商家系統後臺提供自助測試說明文檔，技術人員可按文檔操作示例完成自測試。自測試用例會包括合作業務下每個介面對接測試的說明，以及測試用例點。技術人員需按測試用例點逐一完成自測試工作。',
  txt135: 'UAT環境驗收測試',
  txt136: '當自測試完成後，可聯繫MyLink工作人員。我們會安排在UAT環境的驗收測試，此項工作需您的技術人員一起配合完成。',
  txt137: '當在UAT環境完成驗收測試後，將由MyLink同事在生產環境中配置入口以及訪問白名單，用於生產灰度測試。',
  txt138: '*此時需要使用到在第二步您提供的例如：展示圖片、引導頁等資料，同時會按實際業務流程完成測試用例的執行。此項工作需要業務與技術人員一起配合完成。',
  txt139: '生產環境測試完成後，MyLink會正式發佈生產環境並開始推廣運營。',
  txt140: '在合作內容上線後，可隨時調整推廣策略及宣傳物料，僅需在商家端後臺提交更新相關資料即可。同時為了確保業務正常開始，商戶端後臺支持實時監控心跳（介面）、查看業務交易情況、對賬管理等，智慧化管理讓服務更穩定。',
  txt141: 'MyLink商盟接入平臺將會持續更新更多樣合作模式，商戶可於此查看相關合作方案，輕鬆完成對接。',
  txt142: '為必填',
  txt143: '為必選',
  txt144: '提交成功',
  txt145: '提交失敗',
  txt146: '必須為數字',
  txt147: '請輸入正確格式',
  txt148: '多重賞',
  txt149: '『活動簡介』通過門檻低的遊戲，獎品價值高，完成遊戲可得到商戶優惠。',
  txt150: '『活動數據』累計活動訪問194萬人次 ，活動參與66萬人；',
  txt151: '掃碼賞',
  txt152: '『活動簡介』線上線下宣傳活動，用戶到線下門店掃碼即可獲得門店優惠、MyLink積分及流量包。',
  txt153: '『活動數據』累計活動訪問69萬人次 , 4.5萬人；累計覆蓋線下中移动及商户门店308家。',
  txt154: '累計覆蓋線下商戶308家（MyLink49家、蘇寧13家、卓悅9家、中人壽117家、香港體檢7家）',
  txt155: '流量包累計發放91940份',
  txt156: '加碼賞',
  txt157: '『活動簡介』年終聚合已上線商戶優惠全面升級，吸引用戶完成任務（如：註冊、開戶等）。',
  txt158: '『活動數據』累計活動訪問56萬人次 ,  8萬人 ；9家商戶提升了offer，較日常轉化率提升了40%。',
  txt159: '七夕活動',
  txt160: '『活動簡介』從為男/女朋友選購七夕禮物角度，聚合推薦人氣、熱門的商品。',
  txt161: '用戶權益合作（採購合作）',
  txt162: '玩遊戲得獎勵，跨年著數多重賞',
  txt163: '獎品及商戶優惠券累計發放18.5萬份，價值9千萬港幣。',
  txt164: '線上線下門店互動，跨年著數掃碼賞',
  txt165: '商戶優惠年終大促，跨年著數加碼賞',
  txt166: '年終品牌月活動，5G新年狂歡賺盡生活獎賞',
  txt167: '『活動數據』活動累計訪問20萬人次，1萬人，交易筆數為361筆；',
  txt168: '在第四步驗收妥當上線宣傳。',
  txt169: '上線後可輕鬆管理活動內容。',
  txt170: '數據截止到：2022年3月',
  txt171: '玩遊戲送優惠，冬奧有獎趣味競猜',
  txt172: '業務合作推廣活動，跨境匯款多匯賞',
  txt173: '節日電商推廣活動，七夕約惠浪漫助攻',
  txt174: '玩遊戲送優惠，向月亮許願',
  txt175: '海量客戶群、精准用戶畫像+強大用戶觸達能力、性價比極高推廣合作',
}