import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'
import Introduction from '../views/introduction/index.vue'
import SettleIn from '../views/settleIn/index.vue'
import Step2 from '../views/settleIn/step2.vue'
import Step3 from '../views/settleIn/step3.vue'
import Step4 from '../views/settleIn/step4.vue'
import Step5 from '../views/settleIn/step5.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/home"
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/introduction',
    name: 'Introduction',
    component: Introduction
  },
  {
    path: '/settleIn/step1',
    name: 'SettleIn',
    component: SettleIn,
  },
  {
    path: '/settleIn/step2',
    name: 'SettleIn',
    component: Step2,
  },
  {
    path: '/settleIn/step3',
    name: 'SettleIn',
    component: Step3,
  },
  {
    path: '/settleIn/step4',
    name: 'SettleIn',
    component: Step4,
  },
  {
    path: '/settleIn/step5',
    name: 'SettleIn',
    component: Step5,
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
