<template>
  <div class="wrap">

    <!-- header -->
    <div class="layer1 flex-row justify-center align-center">
      
      <div class="content justify-center align-center">
        <!-- logo -->
        <div class="group1 flex-row justify-center align-center">
          <router-link to="/home" class="">
            <img
              class="label1"
              referrerpolicy="no-referrer"
              src="./assets/img/logo.png"
            />
            <span class="info1">{{ $t('title') }}</span>
          </router-link>
        </div>
        
        <div class="group2 flex-row">
          <router-link  class="word1 active" to="/home">{{ $t('nav1') }}</router-link>
          <router-link  class="word1 " to="/introduction">{{ $t('nav2') }}</router-link>
          <router-link  class="word1 " to="/settleIn/step1">{{ $t('nav3') }}</router-link>
        </div>
      </div>
      <!-- <div class="group3 flex-row justify-center align-center">
        <img
          class="label2"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng92e66123570e03a704db124af0122b6ccc5c854875de6b8c41a181099ed78a34.png"
        />
        <router-link to="/">{{$t('login')}}</router-link>
        <span class="word5">繁</span>
        <span class="word6">简</span>
      </div> -->
    </div>


    <!-- 轮播图 -->
    <Swiper ref="swiper" class="swiper" :autoPlay="false" :showIndicator="false">
      <Slide ref="sider1" class="sider1 wh_slide">
        <div class="content" style="z-index:10;">
          <img src="./assets/img/font1.png">
        </div>
      </Slide>
    </Swiper>


    <!-- 关于MyLink -->
    <div class="layer2">
      <div class="content">
        <span class="txt1">{{$t('txt1')}}</span>
        <span class="info2">
          {{$t('txt2')}}
        </span>
        <div class="layer3">
          <div class="info3">
            <span class="txt2">{{$t('txt3')}}</span>
            <span class="txt3">{{$t('txt4')}}</span>
          </div>
          <div class="info4">
            <span class="txt2">{{$t('txt5')}}</span>
            <span class="txt3">{{$t('txt6')}}</span>
          </div>
          <div class="info5">
            <span class="txt2">{{$t('txt7')}}</span>
            <span class="txt3">{{$t('txt8')}}</span>
          </div>
          <div class="info6">
            <span class="txt2">{{$t('txt9')}}</span>
            <span class="txt3">{{$t('txt10')}}</span>
          </div>
        </div>

        <div class="layer4">
          <div class="info7">
            <img src="./assets/img/icon1.png" alt="">
            <p class="txt4">{{$t('txt11')}}</p>
            <p class="txt5">4,200,000<em>+</em></p>
          </div>
           <div class="info8">
            <img src="./assets/img/icon2.png" alt="">
            <p class="txt4">{{$t('txt12')}}</p>
            <p class="txt5">200,000<em>+</em></p>
          </div>
          <div class="info9">
            <img src="./assets/img/icon3.png" alt="">
            <p class="txt4">{{$t('txt13')}}</p>
            <p class="txt5">900,000<em>+</em></p>
          </div>
        </div>

        <div class="layer5">
          <div class="info10">
            <img src="./assets/img/icon4.png" alt="">
            <p class="txt6">&nbsp;&nbsp;{{$t('txt14')}}</p>
            <p class="txt7">{{$t('txt15')}}<em>1</em></p>
            <p class="txt7">{{$t('txt16')}}<em>3</em></p>
          </div>
           <div class="info11">
            <img src="./assets/img/icon5.png" alt="">
            <p class="txt6">&nbsp;&nbsp;{{$t('txt17')}}</p>
            <p class="txt7">{{$t('txt18')}}<em>2</em></p>
            <p class="txt7">{{$t('txt19')}}<em>5</em></p>
          </div>
          <div class="bar">{{$t('txt170')}}</div>
        </div>
      </div>
    </div>


    <!-- 入駐流程 -->
    <div class="layer6">
      <div class="content">
        <span class="txt1">{{$t('txt20')}}</span>
        <div class="step step1">
          <div class="arrorbg">
            <p class="p1">{{$t('txt21')}}</p>
            <p class="p2">{{$t('txt22')}}</p>
          </div>
          <p class="p3">{{$t('txt23')}}</p>
          <p class="p3">{{$t('txt24')}}</p>
        </div>
        <div class="step step6">
          <div class="step step2">
            <div class="arrorbg">
              <p class="p1">{{$t('txt25')}}</p>
              <p class="p2">{{$t('txt36')}}</p>
            </div>
            <p class="p3">{{$t('txt27')}}</p>
            <p class="p3">{{$t('txt28')}}</p>
          </div>
          <div class="line"></div>
          <div class="step step3">
            <div class="arrorbg">
              <p class="p1">{{$t('txt29')}}</p>
              <p class="p2">{{$t('txt36')}}</p>
            </div>
            <p class="p3">{{$t('txt31')}}</p>
            <p class="p3">{{$t('txt32')}}</p>
            <p class="p3">{{$t('txt33')}}</p>
            <p class="p3">{{$t('txt34')}}</p>
          </div>
        </div>
        
        <div class="step step4">
          <div class="arrorbg">
            <p class="p1">{{$t('txt35')}}</p>
            <p class="p2">{{$t('txt26')}}</p>
          </div>
          <p class="p3">{{$t('txt37')}}</p>
          <p class="p3">{{$t('txt38')}}</p>
        </div>
        <div class="step step5" style="margin-right:0">
          <div class="arrorbg">
            <p class="p1">{{$t('txt39')}}</p>
            <p class="p2">{{$t('txt30')}}</p>
          </div>
          <p class="p3">{{$t('txt41')}}</p>
        </div>

        <router-link to="/introduction?join=1" class="btn1">{{$t('txt42')}}</router-link>
      </div>
    </div>

    <!-- footer -->
    <div class="footer">
      <div class="content">
        <div class="layer7">
          <img src="./assets/img/logo2.png" alt="">
          <p class="p1">&copy;&nbsp;{{year}}&nbsp;&nbsp;{{$t('txt43')}}</p>
        </div>
        <div class="layer8">
          <p class="p1">{{$t('txt44')}} </p>
          <p class="p2">{{$t('txt45')}}</p>
          <div>
            <div class="block1">
              <img src="./assets/img/icon6.png" alt="">
              <p class="p3">{{$t('txt46')}}</p>
            </div>
            <div class="block1">
              <img src="./assets/img/icon7.png" alt="">
              <p class="p3 p4">{{$t('txt47')}}</p>
            </div>
            <a href="https://www.facebook.com/ChinaMobileHK/" target="_blank"><img src="./assets/img/icon8.png" class="img1" alt=""></a>
            <a href="https://www.instagram.com/chinamobilehk/" target="_blank"><img src="./assets/img/icon9.png" class="img2" alt=""></a>
            <a href="https://www.youtube.com/user/chinamobilehongkong" target="_blank"><img src="./assets/img/icon10.png" class="img3" alt=""></a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Swiper, Slide } from 'vue-swiper-component';

export default {
  name: 'Home',
  components: {
    Swiper,
    Slide
  },
  props: [],
  data() {
    return {
      year: (new Date()).getFullYear()
    }
  },
  computed: {},
  watch: {},
  methods: {
    init(){
      this.$nextTick(() => {
        this.$refs['sider1'].$el.style.width = document.body.offsetWidth
        this.$refs['sider1'].$el.style.height = (document.body.offsetWidth * 400)/1440 + 'px'
        this.$refs['swiper'].$el.style.height = (document.body.offsetWidth * 400)/1440 + 'px'
      })
    },
    
  },
  created() {
    this.init()
  },
  mounted() {
    window.onresize = ()=> {
      this.init()
    }
  },
  //beforeDestroy() {},
  //update() {},
  //activited() {},
  //beforeRouteUpdate() {},
  //filters: {},
};
</script>

<style scoped lang="css" src="./assets/index.css" />