import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n)

import zh from './zh-cn'
import zh_TW from './zh-hk'
import en from './en-us'

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'zh_TW',
  messages: {
    zh,
    zh_TW,
    en
  }
})

// 重新封装方法
export function $t(args) {
  return i18n.tc.call(i18n, args);
}

export default i18n